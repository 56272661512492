* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Microsoft YaHei";
  font-size: 14px;
}

html,body{
  width: 100%;
  height: 100%;
  font-size: 14px;
}
body{
  display: table;
}
input, select,input:focus, select:focus {
  outline: none;
}
button{
  border: 0;
  background-color: transparent;
  outline: none;
}
ul,li{
  list-style: none;
}
p{
  margin-bottom: 0;
}
$color-primary: #4b56a5;
input::-webkit-input-placeholder{
  color: #999999;
}
textarea::-webkit-input-placeholder{
  color: #999999;
}
a{ 
  text-decoration:none; 
  color:#333; 
} 
.wrapper-content{
  width: 1200px;
  height: 100%;
  margin:0 auto;
  // margin-bottom: 40px;
}
#remindModal{
  .C_remindContent{
    line-height: 40px;
    text-align: center;
    margin-top: 40px;
    .iconfont{
      font-size: 67px;
      color: #4b56a5;
    }
    div{
      font-size: 16px;
      margin: 20px;
    }
  }
  .C_remindTitle{
    height: 55px;
    line-height: 55px;
    text-align: center;
    color: #333333;
    background: #F8F8F8;
    border:1px solid #dddddd;
    position: relative;
  }
  .modal-footer{
    text-align: center;
    display: inline-block;
    .btn{
      padding: 0 30px;
      height: 35px;
      border:1px solid #dddddd;
      margin:30px auto;
      color: #333333;
      font-size: 16px;
    }
    .btn-primary{
      background: #4b56a5;
      border-color: #4b56a5;
      color: #FFFFFF;
      margin-right: 15px;
    }
    .btn-primary:hover{
      background: #4b56a5!important;
      border-color: #4b56a5!important;
    }
    .btn:nth-child(1){
      display: none;
    }
  }
}

/*查看图片*/
.shade{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(175, 171, 171, 0.8);
  z-index: 99999999;
  overflow: auto;
}

.shade img {
  display: block;
  margin: 80px auto 10px;
  width: 0;
  height: 0;
  transition: width 0.3s, height 0.3s;
  -moz-transition: width 0.3s, height 0.3s;
  -webkit-transition: width 0.3s, height 0.3s;
  -o-transition: width 0.3s, height 0.3s;
  overflow: auto;
}
.C_seeBigImg{
  cursor: pointer;
}

.C_table1 {
  display: table;
  background: #FFFFFF;
  width: calc(100% - 70px);
  height: auto;
  margin: 20px auto;
  overflow: hidden;
  box-shadow: 3px 3px 10px #DDDDDD;
}

.C_table1 tr:nth-child(2n) {
  background: #f5f5f5;
}
.C_table1 tr td {
  height: 46px;
  padding: 5px;
  text-align: center;
}
.C_table1 thead tr {
  background: #f3f4fa;
  height: 32px;
  line-height: 32px;
}
.C_table1 thead th{
  color: #666666;
  text-align: center;
}
#myModal3 .modal-dialog {
  width: auto;
  max-width: 580px!important;
  height: auto;
  margin: 200px auto;
  overflow: auto;
}
#myModal3{
  overflow: auto!important;
}
#myModal3 .modal-dialog .modal-content {
  width: 580px;
  max-width: 580px!important;
  height: auto;
}

#myModal3 .modal-dialog .modal-content .C_modal-header{
  text-align: center;
  line-height: 60px;
  font-size: 16px;
  color: #333333;
  background: #F8F8F8;
  border:1px solid #dddddd;
  position: relative;
}
#myModal3 .modal-dialog .modal-content .C_modal-header .C_modal-close{
  position: absolute;
  top:15px;
  right: 15px;
  width: 20px;
  height: 20px;
  line-height: 18px;
  background: #959595;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

#myModal3 .modal-dialog .modal-content .modal-footer {
  width: 100%;
  margin-top: 20px;
  padding-right: 30px;
  padding-top: 26px;
  padding-bottom: 26px;
  border-top: none;
  justify-content:center;
}
#myModal3 .modal-dialog .modal-content .modal-footer button{
  width: 120px;
  height: 40px;
  font-size: 14px;
  margin: 0 30px;
}
#myModal3 .modal-dialog .modal-content .modal-body {
  height: auto;
  padding: 0;
}

#myModal3 .modal-dialog .modal-content .modal-body ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

#myModal3 .modal-dialog .modal-content .modal-body ul li {
  display: block;
  width: 100%;
  height: auto;
  min-height: 34px;
  margin:20px 0;
}

#myModal3 .modal-dialog .modal-content .modal-body ul li>span:first-child {
  width: 70px;
  height: 100%;
  display: block;
  float: left;
  text-align: left;
  line-height: 34px;
  font-size: 14px;
  color: #666666 !important;
  margin-left: 85px;
  font-weight: bolder;
}

#myModal3 .modal-dialog .modal-content .modal-body ul li i {
  font-style: normal;
  line-height: 34px;
  display: block;
  float: left;
  margin-left: 20px;
}
#myModal3 .modal-dialog .modal-content .modal-body ul li input {
  width: 300px;
  display: inline-block;
  margin-left: 40px;
}
#myModal3 .modal-dialog .modal-content .modal-body ul {
  li.C_otherProperty,li.C_otherType{
    display: none;
  }
}
#myModal3 .modal-dialog .modal-content .modal-body ul li .errorText{
  padding-left: 190px;
  margin-top: 10px;
  display: none;
  span:nth-child(1){
    font-size: 18px;
    color: #ff944d;
    margin-left: 5px;
    margin-right: 5px;
  }
  span:nth-child(2){
    color: #999999;
    font-size: 12px;
  }

}
select::-ms-expand { display: none; }
select {
  /* 清除默认边框 */
  border: 0;
  /* 清除默认的箭头样式 */
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  -ms-appearance:none;
  background:none;
  /* 右侧添加小箭头的背景图 */
  background: url('../assets/images/sales/down.png')  center no-repeat;
  background-size: 12px 6.8px;
  background-position: right 10px center;
}
.C_loading{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999999999;
  background: rgba(255,255,255,0.5);
  display: none;
}
.C_loadingMsg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 200px;
}
.C_loadingMsg>img{
  width: 92px;
  height: 92px;
  margin: 0 104px;
  display: inline-block;
}
.C_loadingMsg>div{
  width: 100%;
  height: auto;
  color: #4b56a5;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}